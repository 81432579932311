<form (ngSubmit)="onSubmit()" [formGroup]="form">
	<itd-mat-sidebar [size]="480" icon="filter">
		<ng-template #header>
			{{ 'FILTERS.HEADER' | translate }}
		</ng-template>

		<ng-template #content>
			<div class="filters-container">
				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.email">
					<div class="filters-item">
						<itd-input
							class="input"
							name="email"
							formControlName="email"
							label="EMAIL"
							placeholder="SETTINGS.EMAIL_PLACEHOLDER"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
						></itd-input>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.phone">
					<div class="filters-item">
						<itd-input
							class="input"
							name="phone_number"
							formControlName="phone_number"
							label="PHONE"
							(write)="prependCountryCodeIndicator()"
							placeholder="SETTINGS.PHONE_PLACEHOLDER"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
						></itd-input>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.firstName">
					<div class="filters-item">
						<itd-input
							class="input"
							name="first_name"
							formControlName="first_name"
							label="FIRST_NAME"
							placeholder="SETTINGS.FIRST_NAME_PLACEHOLDER"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
						></itd-input>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.lastName">
					<div class="filters-item">
						<itd-input
							class="input"
							name="last_name"
							formControlName="last_name"
							label="LAST_NAME"
							placeholder="SETTINGS.LAST_NAME_PLACEHOLDER"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
						></itd-input>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.userPending">
					<div class="filters-item">
						<itd-select
							formControlName="is_pending"
							printLabelIndex="title"
							outputValueIndex="value"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
							[label]="'FILTERS.USER_STATUS' | translate"
							[data]="[
								{ value: 'Active', title: 'FILTERS.USER_STATUS_ACTIVE' | translate },
								{ value: 'Pending', title: 'FILTERS.USER_STATUS_PA' | translate }
							]"
						>
						</itd-select>
					</div>
				</itd-grid-item>
				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.userStatus">
					<div class="filters-item">
						<itd-select
							formControlName="status"
							printLabelIndex="title"
							outputValueIndex="value"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
							[label]="'FILTERS.ASSIGNMENT_STATUS' | translate"
							[data]="[
								{ value: 'started', title: 'FILTERS.USER_STATUS_STARTED' | translate },
								{ value: 'notstarted', title: 'FILTERS.USER_STATUS_NOT_STARTED' | translate },
								{ value: 'done', title: 'FILTERS.USER_STATUS_DONE' | translate }
							]"
						>
						</itd-select>
					</div>
				</itd-grid-item>

				<itd-grid-item
					size="100%"
					[mobileResponsive]="true"
					*ngIf="shownFields.userRole && availableUserRoles?.length !== 0"
					><div class="filters-item">
						<itd-select
							formControlName="user_role"
							printLabelIndex="title"
							outputValueIndex="value"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
							[label]="'USER_ROLE' | translate"
							[data]="availableUserRoles"
						></itd-select>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.department">
					<div class="filters-item">
						<itd-select
							formControlName="dept_string"
							printLabelIndex="title"
							outputValueIndex="department_id"
							sizeType="small"
							[hasShadow]="true"
							[hasBorder]="false"
							[label]="'FILTERS.DEPARTMENT' | translate"
							[observableData]="pipedDepartments$"
						></itd-select>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.department">
					<div class="filters-item">
						<itd-checkbox formControlName="is_deleted">{{
							'FILTERS.SHOW_DELETED' | translate
						}}</itd-checkbox>
					</div>
				</itd-grid-item>

				<itd-grid-item size="100%" [mobileResponsive]="true" class="confirm">
					<div class="filters-item">
						<itd-button [enableAfterSuccess]="true" [formState]="formState" [width]="223">
							{{ 'CONFIRM' | translate }}
						</itd-button>
					</div>
				</itd-grid-item>
				<itd-grid-item size="100%" [mobileResponsive]="true" *ngIf="shownFields.department">
					<div class="reset-btn-wrapper">
						<button
							*ngIf="sidenavService.getResetFilterStatus()"
							type="button"
							class="btn btn--color-secondary btn--size-small"
							(click)="resetFilters()"
						>
							<span>{{ 'FILTERS.RESET' | translate }}</span>
						</button>
					</div>
				</itd-grid-item>
			</div>
		</ng-template>
		<ng-template #footer> </ng-template>
	</itd-mat-sidebar>
</form>
