<div class="department-manager">
	<section
		class="department-list"
		*ngFor="let departmentSection of departmentsSections$ | async; let i = index"
	>
		<itd-department-section
			[departmentSection]="departmentSection"
			[index]="i"
			[user]="user"
			[selectedDepartmentId]="selectedDepartmentId"
			(departmentSelected)="this.departmentSelected.emit({selectedDepartmentId: $event, i})"
		></itd-department-section>
	</section>
</div>
