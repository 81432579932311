import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';
import {
	AddParent_id,
	DeleteDepartment,
	LoadDepartmentsFromParent,
} from 'src/app/core/department/department.actions';
import { IDepartment } from 'src/app/core/department/department.interface';
import { IUser } from 'src/app/core/users/users.interface';
import { ConfirmDeleteModalComponent } from 'src/app/shared/modals/confirm-delete-modal/confirm-delete-modal.component';
import { EditDepartmentModalComponent } from 'src/app/shared/modals/edit-department-modal/edit-department-modal.component';
import { SearchPipe } from '../../pipes/search.pipe';
import { MenuItemComponent } from '../../../../../../shared/components/menu/menu-item/menu-item.component';
import { MenuComponent } from '../../../../../../shared/components/menu/menu.component';
import { ButtonComponent } from '../../../../../../utility-modules/itd-form/components/button/button.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { MoveDepartmentModalComponent } from 'src/app/shared/modals/move-department-modal/move-department-modal.component';

@Component({
	selector: 'itd-department-section',
	templateUrl: './department-section.component.html',
	styleUrls: ['./department-section.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		ReactiveFormsModule,
		FormsModule,
		ButtonComponent,
		NgFor,
		NgClass,
		MenuComponent,
		MenuItemComponent,
		TranslateModule,
		SearchPipe,
	],
})
export class DepartmentSectionComponent {
	@Input() public departmentSection: IDepartment[];
	@Input() public index: number;
	@Input() public user: IUser;
	@Input() public selectedDepartmentId: string;
	@Output() public departmentSelected = new EventEmitter<string>();
	public searchText: string;
	constructor(public store: Store, private modals: NgbModal, private translate: TranslateService) {}

	public getDepartment(department_id) {
		this.departmentSelected.emit(department_id);
		this.store.dispatch(new LoadDepartmentsFromParent({ department_id, index: this.index + 1 }));
		this.store.dispatch(
			new AddParent_id({
				parent_id: department_id,
				index: this.index,
			})
		);
	}
	public async delete(title: string, department_id) {
		const component = this.modals.open(ConfirmDeleteModalComponent)
			.componentInstance as ConfirmDeleteModalComponent;

		const _content: string = await firstValueFrom(this.translate.get(`DEPARTMENTS.DELETE_CONFIRM`));

		component.data = {
			content: _content.replace('{{title}}', title),
			confirmText: title,
		};

		component.onSubmitAction = () => {
			return this.store.dispatch([new DeleteDepartment({ department_id, index: this.index })]);
		};
	}
	public async edit(department: IDepartment) {
		const component = this.modals.open(EditDepartmentModalComponent)
			.componentInstance as EditDepartmentModalComponent;
		component.data = {
			isEdit: true,
			department,
			index: this.index,
		};
	}
	public async create() {
		const component = this.modals.open(EditDepartmentModalComponent)
			.componentInstance as EditDepartmentModalComponent;
		component.data = {
			isEdit: false,
			index: this.index,
		};
	}

	public async move(department: IDepartment) {
		const component = this.modals.open(MoveDepartmentModalComponent)
			.componentInstance as MoveDepartmentModalComponent;
		component.departmentToMove = department;
	}
}
