import { IPagination } from '../constans';
import { IUserEdit, IUserInvite, IUserInviteFlow } from './users.interface';

export class LoadAllUsers {
	public static readonly type = '[User] Load All';
	constructor(public params: { params: { skip: number; limit: number } }) {}
}

export class LoadUsers {
	public static readonly type = '[User] Load Users';
	constructor(public params: { params: { skip: number; limit: number } }) {}
}

export class LoadUsersWithFilters {
	public static readonly type = '[User] Load Users With Filters';
	constructor(
		public params: {
			params: {
				skip: number;
				limit: number;
				email?: string;
				phone?: string;
				first_name?: string;
				last_name?: string;
				user_status?: string;
				user_role?: string;
				department?: string;
			};
		}
	) {}
}

export class LoadAllUsersIfEmpty {
	public static readonly type = '[User] Load All If Empty';
	constructor(public params: { params: { skip: number; limit: number } }) {}
}

export class CreateUser {
	public static readonly type = '[User] Create';
	constructor(public payload: { data: IUserEdit }) {}
}

export class UpdateUser {
	public static readonly type = '[User] Update';
	constructor(public payload: { id: string; data: Partial<IUserEdit> }) {}
}

export class DeleteUser {
	public static readonly type = '[User] Delete';
	constructor(public payload: { id: string }) {}
}

export class InviteMultipleUsers {
	public static readonly type = '[User] Invite Multiple';
	constructor(
		public payload?: { org_id: string; invites: IUserInvite | IUserInvite[]; department_id: string }
	) {}
}

export class InviteSingleUser {
	public static readonly type = '[User] Invite Single';
	constructor(public payload?: IUserInviteFlow) {}
}

export class AddPaginationUser {
	public static readonly type = '[User] Add Pagination';
	constructor(public payload?: { pagination: IPagination }) {}
}
export class PublicSignUp {
	public static readonly type = '[User] Public SignUp';
	constructor(public payload: any) {}
}
