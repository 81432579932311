import { Injectable } from '@angular/core';
import { ApiClientService } from '../api/api.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TResults } from '../models/results.interface';
import { map } from 'rxjs/operators';
import { AbstractApiService } from '../abstract/abstract-api-service';
import { ISuperAdminUser, ISuperAdminUserDTO, ISuperAdminUserEdit, ISuperAdminUserEditDTO, ISuperAdminUserInvite } from './superadmin-users.interface';
import { AddLoading, RemoveLoading } from '../layout/layout.actions';
import { AddPagination } from './superadmin-users.action';

@Injectable({
	providedIn: 'root',
})
export class SuperAdminUserService extends AbstractApiService<ISuperAdminUser, ISuperAdminUserDTO, ISuperAdminUserEdit, ISuperAdminUserEditDTO> {
	protected key: string = 'users';

	constructor(protected api: ApiClientService, store: Store) {
		super(api, store);
	}

	public invite(data: ISuperAdminUserInvite): Observable<ISuperAdminUser> {
		return this.api
			.post<TResults<ISuperAdminUserDTO>>('api/invite-flow', data, {})
			.pipe(map(response => this.parse(response.data)));
	}
	public override getAll(params: any): Observable<ISuperAdminUser[]> {		
		this.store.dispatch(AddLoading);
		return this.api
			.get<TResults<ISuperAdminUserDTO[]>>('api/users', params)
			.pipe(map(response => {
        this.store.dispatch(RemoveLoading);
				this.store.dispatch(new AddPagination({pagination: response?.pagination}))
        return response.data.map(user => this.parse(user))
      }))
	}

	protected parse(value: ISuperAdminUserDTO): ISuperAdminUser {
		return {
			...value,
		};
	}

	protected parseEditDTO(value: ISuperAdminUserEdit): ISuperAdminUserEditDTO {
		// TODO: Implement parseEditDTO of superadmin Users method
		throw new Error('Method not implemented.');
	}
}
