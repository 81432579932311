import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class HighlightPipeConfig {
	highlightClass = 'highlight';

	setHighlightClass(highlightClass: string): this {
		this.highlightClass = highlightClass;
		return this;
	}

	isValidUrl(string) {
		try {
			new URL(string);
			return true;
		} catch (err) {
			return false;
		}
	}
}

@Pipe({
    name: 'highlightLinks',
    standalone: true,
})
export class HighlightLinksPipe implements PipeTransform {
	constructor(private domSanitizer: DomSanitizer) {}

	transform(value: string, config = new HighlightPipeConfig()): SafeHtml {
		const { highlightClass, isValidUrl } = config;

		let arrayOfNewLines = value.split(/\r?\n/);
		let arrayOfChangedLines = [];
		arrayOfNewLines.forEach(line => {
			let arrayOfStrings = line.split(' ');
			let arrayOfChangedStrings = [];
			arrayOfStrings.forEach(word => {
				let url = isValidUrl(word) ? new URL(word) : null;
				if (!url) {
					arrayOfChangedStrings.push(word);
				} else {
					const newString = `<a class="${highlightClass}" href="${url.href}"><img	src="https://s2.googleusercontent.com/s2/favicons?domain_url=${url.href}"/> ${url.hostname}</a>`;
					arrayOfChangedStrings.push(newString);
				}
			});
			const rawLineHTML = arrayOfChangedStrings.join(' ');
			arrayOfChangedLines.push(rawLineHTML);
		});
		const rawHTML = arrayOfChangedLines.join('\r\n');

		return this.domSanitizer.bypassSecurityTrustHtml(rawHTML);
	}
}
