import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LoadAllParentDepartments } from 'src/app/core/department/department.actions';
import { IDepartment } from 'src/app/core/department/department.interface';
import { DepartmentState } from 'src/app/core/department/department.state';
import { IOrganization } from 'src/app/core/organizations/organizations.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { IUser } from 'src/app/core/users/users.interface';
import { DepartmentSectionComponent } from './components/department-section/department-section.component';
import { NgFor, AsyncPipe } from '@angular/common';

@Component({
	selector: 'itd-admin-departments-page',
	templateUrl: './admin-departments-page.component.html',
	styleUrls: ['./admin-departments-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgFor, DepartmentSectionComponent, AsyncPipe],
})
export class AdminDepartmentsPageComponent implements OnInit {
	@Input() public user: IUser;
	@Input() public selectedDepartmentId: string;
	@Output() public departmentSelected = new EventEmitter<any>();
	@Select(ProfileState.profile) profile$: Observable<IProfile>;
	@Select(OrganizationState.organization) organization$: Observable<IOrganization>;
	@Select(DepartmentState.departments) departments$: Observable<IDepartment[]>;
	@Select(DepartmentState.departmentsSection) public departmentsSections$: Observable<
		IDepartment[][]
	>;

	constructor(private store: Store) {}

	ngOnInit(): void {
		this.store.dispatch(LoadAllParentDepartments);
	}
}
