import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, map, Observable } from 'rxjs';
import { IDepartment } from 'src/app/core/department/department.interface';
import { OrganizationState } from 'src/app/core/organizations/organizations.state';
import { ProfileState } from 'src/app/core/profile/profile.state';
import { ValidationMessagesService } from 'src/app/core/services/validation-messages.service';
import { UpdateUser } from 'src/app/core/users/users.action';
import { IUser, IUserEditDTO, IUserRole } from 'src/app/core/users/users.interface';
import { AbstractFormComponent } from 'src/app/utility-modules/itd-form/components/abstract-form/abstract-form.component';
import { NotificationService } from 'src/app/utility-modules/notification/services/notification.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { UsersState } from 'src/app/core/users/users.state';
import { removeAllOutsideDepartment } from 'src/app/core/utils/departments.util';
import { OrganizationService } from 'src/app/core/organizations/organizations.service';
import { SetToken } from 'src/app/core/auth/auth.action';
import { GetMyOrganization } from 'src/app/core/organizations/organizations.actions';
import { GetProfile } from 'src/app/core/profile/profile.actions';
import { IProfile } from 'src/app/core/profile/profile.interface';
import { ButtonComponent } from '../../../utility-modules/itd-form/components/button/button.component';
import { FormErrorComponent } from '../../../utility-modules/itd-form/components/form-error/form-error.component';
import { InputDateComponent } from '../../../utility-modules/itd-form/components/input-date/input-date.component';
import { SelectComponent } from '../../../utility-modules/itd-form/components/select/select.component';
import { InputUserComponent } from '../../../utility-modules/itd-form/components/input-user/input-user.component';
import { InputPhoneComponent } from '../../../utility-modules/itd-form/components/input-phone/input-phone.component';
import { InputComponent } from '../../../utility-modules/itd-form/components/input/input.component';
import { GridItemComponent } from '../../../utility-modules/grid/components/grid-item/grid-item.component';
import { GridComponent } from '../../../utility-modules/grid/components/grid/grid.component';
import { NgIf } from '@angular/common';
import { ModalComponent } from '../../../utility-modules/modals/components/modal/modal.component';

type FormValue = {
	email: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	job_title: string;
	user_role: IUserRole;
	department?: string;
	organization?: string;
	manager_id?: string;
	start_date?: Date;
};

@Component({
	selector: 'itd-edit-user-modal',
	templateUrl: './edit-user-modal.component.html',
	styleUrls: ['./edit-user-modal.component.scss'],
	standalone: true,
	imports: [
		ReactiveFormsModule,
		ModalComponent,
		NgIf,
		GridComponent,
		GridItemComponent,
		InputComponent,
		InputPhoneComponent,
		InputUserComponent,
		SelectComponent,
		InputDateComponent,
		FormErrorComponent,
		ButtonComponent,
		TranslateModule,
	],
})
export class EditUserModalComponent extends AbstractFormComponent<FormValue> implements OnInit {
	public form: FormGroup;
	public availableUserRoles: object[];
	public userDepartmentId: string;
	public isGlobalAdmin: boolean;
	public filteredManagers$: Observable<IUser[]>;
	private userRole: string;

	@Input() public user: IUser;
	@Input() public admin: boolean;
	@Input() public superAdmin = false;

	@Output() public error = new EventEmitter<HttpErrorResponse>();
	@Output() public success = new EventEmitter<void>();

	@Select(OrganizationState.items) public organizations$: Observable<IDepartment[]>;
	@Select(UsersState.items) public users$: Observable<IUser[]>;
	@Select(ProfileState.profile) public user$: Observable<IProfile>;

	constructor(
		public store: Store,
		public validationMessages: ValidationMessagesService,
		public elementRef: ElementRef,
		protected cd: ChangeDetectorRef,
		private activeModal: NgbActiveModal,
		private notification: NotificationService,
		private translate: TranslateService,
		private orgService: OrganizationService
	) {
		super(cd);
	}

	async ngOnInit(): Promise<void> {
		this.user$.subscribe(usr => {
			this.userDepartmentId = usr.department_id;
			this.isGlobalAdmin = usr.user_role === 'super_admin' || usr.user_role === 'org_admin';
			this.userRole = usr.user_role;
		});
		this.form = new FormGroup({
			email: new FormControl(this.user.email),
			first_name: new FormControl(this.user.first_name),
			last_name: new FormControl(this.user.last_name),
			phone_number: new FormControl(this.user.phone_number),
			job_title: new FormControl(this.user.job_title),
			user_role: new FormControl(this.user.user_role),
			manager_id: new FormControl(this.user.manager?.user_id),
			organization: new FormControl(this.user.org_id),
			start_date: new FormControl(this.user.start_date),
		});
		this.afterInit();
		this.availableUserRoles = await this.loadAvailableUserRoles(this.userRole);
		this.filteredManagers$ = this.users$.pipe(
			map(users => {
				users = users.filter(user => user.first_name && user.last_name);
				return this.isGlobalAdmin
					? users
					: removeAllOutsideDepartment(this.userDepartmentId, users);
			})
		);
	}

	public onSubmitAction() {
		if (this.formValue.organization !== this.user.org_id) {
			this.orgService.changeMyOrg(this.user.user_id, this.formValue.organization).subscribe(res => {
				const token = res.token;
				if (!!token) {
					this.store.dispatch([new SetToken({ token }), GetMyOrganization, GetProfile]);
				}
			});
		}

		return this.store.dispatch(new UpdateUser({ id: this.user.user_id, data: this.parse() }));
	}

	public onSubmitSuccess() {
		this.notification.add({
			text: 'BACKEND_MESSAGES.USER_UPDATE_SUCCESS',
		});

		this.activeModal.close();
	}

	public async loadAvailableUserRoles(role: string): Promise<object[]> {
		if (role === 'super_admin') {
			return [
				{ value: 'super_admin', title: await this.getRoleTranslation('USER_ROLE_SUPER_ADMIN') },
				{ value: 'org_admin', title: await this.getRoleTranslation('USER_ROLE_ORG_ADMIN') },
				{ value: 'dept_admin', title: await this.getRoleTranslation('USER_ROLE_DEPT_ADMIN') },
				{ value: 'std_user', title: await this.getRoleTranslation('USER_ROLE_STD_USER') },
			];
		} else if (role === 'org_admin' || role === 'dept_admin') {
			return [
				{ value: 'dept_admin', title: await this.getRoleTranslation('USER_ROLE_DEPT_ADMIN') },
				{ value: 'std_user', title: await this.getRoleTranslation('USER_ROLE_STD_USER') },
			];
		} else {
			return [];
		}
	}

	public async getRoleTranslation(role: string) {
		return await firstValueFrom(this.translate.get(`${role}`));
	}

	public parse() {
		const updatedUser: IUserEditDTO = {};
		if (this.formValue.email !== this.user.email) {
			updatedUser.email = this.formValue.email;
		}
		if (this.formValue.first_name !== this.user.first_name) {
			updatedUser.first_name = this.formValue.first_name;
		} else {
			updatedUser.first_name = this.user.first_name;
		}
		if (this.formValue.last_name !== this.user.last_name) {
			updatedUser.last_name = this.formValue.last_name;
		} else {
			updatedUser.last_name = this.user.last_name;
		}
		if (this.formValue.phone_number !== this.user.phone_number) {
			updatedUser.phone_number = this.formValue.phone_number;
		}

		if (this.formValue.job_title !== this.user.job_title) {
			updatedUser.job_title = this.formValue.job_title;
		}

		if (this.formValue.user_role !== this.user.user_role) {
			updatedUser.user_role = this.formValue.user_role;
		} else {
			updatedUser.user_role = this.formValue.user_role;
		}

		if (this.formValue.organization !== this.user.org_id) {
			updatedUser.org_id = this.formValue.organization;
		}

		if (this.formValue.manager_id !== this.user.manager?.user_id) {
			updatedUser.manager_id = this.formValue.manager_id;
		}

		if (this.formValue.start_date !== this.user.start_date) {
			updatedUser.start_date = this.formValue.start_date;
		}

		return updatedUser;
	}
}
